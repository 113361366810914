<template>
    <b-card>

        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
            <b-form @submit.prevent="handleSubmit(onSubmit)">
                <div class="d-flex">
                    <feather-icon icon="LayoutIcon" size="19"/>
                    <h4 class="mb-0 ml-50">Sayfa Bilgileri</h4>
                </div>

                <b-row class="mt-1">
                    <b-col cols="12">
                        <validation-provider #default="validationContext" name="Başlık" rules="required">
                            <b-form-group label="Başlık">
                                <b-form-input trim placeholder="Başlık" v-model="dataInfo.title" :state="getValidationState(validationContext)"/>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col cols="12">
                        <b-form-group label="İçerik">
                            <quill-editor v-model="dataInfo.detail" :options="editorOption"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12 mt-3">
                        <validation-provider #default="validationContext" name="Durum" rules="required">
                            <b-form-group label="Durum" label-for="isActive" :state="getValidationState(validationContext)">
                                <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                </b-row>
                <action-buttons :back-route="'system-page-list'"/>
            </b-form>
        </validation-observer>
        <Overlay :busy="busy"></Overlay>
    </b-card>
</template>

<script>
import {BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback,} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {required, email} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/system/page/store"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import {statusOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue";
import ActionButtons from "@/components/Form/ActionButtons.vue";
import {toastMessage} from "@core/utils/utils";
import {quillEditor} from 'vue-quill-editor'
import {$themeConfig} from "@themeConfig";

export default {
    components: {
        quillEditor,
        ActionButtons,
        Overlay,
        BCard,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        vSelect,
        BButton,
        BFormInvalidFeedback,

        ValidationProvider,
        ValidationObserver,
    },
    props: {},
    data() {
        return {
            required,
            email,
        }
    },
    setup() {
        const STORE_MODULE_NAME = 'store'
        if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
        onUnmounted(() => {
            if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
        })

        const toast = useToast()
        const busy = ref(false)
        const dataInfo = ref({
            id: 0,
            code: '',
            title: '',
            detail: '',
            isActive: true,
        })

        const toolbarOptions = [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{'header': 1}, {'header': 2}],               // custom button values
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
            [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
            [{'direction': 'rtl'}],                         // text direction

            [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
            [{'header': [1, 2, 3, 4, 5, 6, false]}],

            [{'color': []}, {'background': []}],          // dropdown with defaults from theme
            [{'font': []}],
            [{'align': []}],

            ['clean']                                         // remove formatting button
        ];

        const editorOption = {
            modules: {
                toolbar: toolbarOptions,
            },
        }

        if (router.currentRoute.params.id > 0) {
            busy.value = true
            store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
                dataInfo.value = response.data.data
            }).catch(error => {
                toastMessage(toast, 'danger', error.response.data.message)
                if (error.response.status === 403) {
                    router.push({name: 'system-page-list'})
                }
            }).finally(message => {
                busy.value = false
            })
        }

        const onSubmit = () => {
            if (!$themeConfig.app.appIsDemo) {
                busy.value = true
                store.dispatch('store/editItem', dataInfo.value).then(response => {
                    toastMessage(toast, 'success', response.data.message)
                    router.push({name: 'system-page-list'})
                }).catch(error => {
                    toastMessage(toast, 'danger', error.response.data.message)
                }).finally(message => {
                    busy.value = false
                })
            } else {
                toastMessage(toast, 'danger', 'Demo kullanımda işlem yapılamaz!')
            }
        }

        const {
            refFormObserver,
            getValidationState,
            resetForm,
        } = formValidation()

        return {
            busy,
            dataInfo,
            statusOptions,
            editorOption,

            onSubmit,
            refFormObserver,
            getValidationState,
            resetForm,
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';

.quill-editor {
  height: 150px;
}

</style>
